html, body, #root {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 1em;
  height: 100%;
  width: 100%;
}

button, input {
  font-family: inherit;
  font-size: inherit;
}
