@import '../../variables';

.ControlPanel {
  padding: 0px;
  width: 100%;
  height: auto;
  background-color: color($background-colors, second);
  text-align: center;
  // z-index: 3;
  display: flex;
  justify-content: space-between;

  
  @include for-size(tablet-landscape-up) {
    width: auto;
    height: 100%;
    flex-direction: column;
    align-items: center;
    font-size: 1.2em;
  }
}

.Group {
  // padding: $spacer-sm;
  display: flex;
  flex: 0 1 30%;

  &.GroupEnd {
    justify-content: flex-end;
  }

  @include for-size(tablet-landscape-up) {
    flex-direction: column;
  }
}

