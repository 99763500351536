@import '../../variables';

.Workflow {
  display: flex;
  flex-flow: column;
  max-height: 100%;
}

.Content {
  overflow-y: scroll;
  margin-bottom: $spacer-lg;
}