@import '../../../variables';

.UnorderedList {
  padding: 0;
  list-style-type: none;
}

.ListItem {
  cursor: pointer;
  height: 40px;
  border: none;
  padding: $spacer-sm;
  background-color: white;
  line-height: 40px;
  font-size: 20px;
  border-bottom: 3px solid transparent;

  display: flex;
  justify-content: space-between;
}

.Selected {
  border-bottom-color: color($tile-selection-colors, selected);
}

.ListItem {
  margin-bottom: $spacer-sm;
}

.ListItem:hover {
  border-bottom-color: color($tile-selection-colors, hover);
}