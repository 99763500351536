@import '../../variables';

.Notifications {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.Container {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 1000;
}

.Content {
  box-sizing: border-box;
  position: absolute;
  right: 0;
  bottom: 0;
  padding: $spacer-sm;
  display: flex;
  flex-flow: column;
  width: 100%;

  @include for-size(desktop-up) {
    width: 400px;
  }
}
