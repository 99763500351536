@import '../../variables';

@mixin icon-bg-color($color) {
  & > .Icon {
    background-color: darken($color, 25%);
  }
}

.Root {
  display: flex;
  align-items: center;
  margin-bottom: $spacer-lg;
  font-size: $font-size-md;
  $color: map-get($context-background-colors, info);
  background-color: $color;
  @include icon-bg-color($color);
}

.Warning {
  $color: map-get($context-background-colors, warning);
  background-color: $color;
  @include icon-bg-color($color);
}

.Failure {
  $color: map-get($context-background-colors, failure);
  background-color: $color;
  @include icon-bg-color($color);
}


  

.Content {
  padding: $spacer-sm;
  flex: 1 100%;
}

.Icon {
  margin-right: $spacer-sm;
  align-self: stretch;
  padding: $spacer-lg;
  flex: 1 50px;
  display: flex;
  justify-content: center;
  // height: 100%;
}