@import '../../../../variables';

$disabled-bg-color: transparentize(color($tile-selection-colors, disabled), 0.3);

.Disabled {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: $spacer-sm;
  box-sizing: border-box;
  background-color: $disabled-bg-color;
  color: color($tile-selection-colors, color-disabled);
}

.Info {
  font-weight: normal;
  font-size: $font-size-sm;
}