@import '../../../variables';

.ImageSelect {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  width: 100%;
  max-height: 100%;
  padding: $spacer-sm;
  box-sizing: border-box;
  margin-bottom: $spacer-lg;
}

.Option {
  background-color: color($background-colors, input-first);
  $margin-percent: 1%;
  $num-cols: 3;
  margin: $margin-percent;
  display: flex;
  cursor: pointer;
  border-bottom: $spacer-sm solid transparent;
  flex: 0 0 ((100 / $num-cols) - (2 * $margin-percent));
  justify-content: center;
  position: relative;
  align-items: center;
  box-sizing: border-box;
  
  &::after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }

  &:hover:not(.Disabled) {
    border-bottom: $spacer-sm solid color($tile-selection-colors, hover);
  }
  
}


.Content {
  position: absolute;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  top: 0;
  left: 0;
}

.Title {
  background-color: color($background-colors, third);
  color: color($tile-selection-colors, color-hover);
  padding: $spacer-sm;
  flex: 1 60px;
  font-size: 0.7em;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  // align-items: center;
  justify-content: center;
  text-align: center;
  // overflow-wrap: normal;

  @include for-size(desktop-up) {
    font-size: $font-size-md;
  }
}

.Image {
  flex: 1 100%;

  position: relative;

  background-size: 80% 80%;
  background-position: center center;
  background-repeat: no-repeat;
}

.Selected {
  border-bottom: $spacer-sm solid color($tile-selection-colors, selected);

  & .Title {
    background-color: color($tile-selection-colors, selected);
    color: color($tile-selection-colors, color-selected);
  }
}

$disabled-bg-color: transparentize(color($tile-selection-colors, disabled), 0.3);

.Disabled {
  & .Title {
    background-color: color($tile-selection-colors, disabled);
    color: color($tile-selection-colors, color-disabled);
  }
  border-bottom: $spacer-sm solid $disabled-bg-color;
  cursor: not-allowed;
}



