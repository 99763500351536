@import '../../../variables';

.Button {
  background-color: color($background-colors, first);
  border: none;
  border-radius: 2px;
  padding: $spacer-sm $spacer-lg;
  cursor: pointer;
  line-height: 100%;
  text-align: center;
  color: color($colors, first);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: $font-size-md;
}

.Button:hover {
  color: color($colors, first-alt);
}

.Button:focus {
  outline: 0;
}

.Link {
  background-color: transparent;
  border: none;
  border-bottom: 1px dotted color($colors, first);
  color: color($colors, first);
  cursor: pointer;
}

.Plain {
  border: 1px solid transparent;
  
  &:hover {
    border: 1px solid color($colors, first-alt);
  }
}


.Standard {
  background-color: color($background-colors, second);
  color: color($colors, second);

  &:hover {
    background-color: dark-color($background-colors, second, 10%);
    color: white;
  }
}

.Notification {

  border: 1px solid transparent;

  &.Info {
    background-color: dark-color($context-background-colors, info);
    color: color($context-colors-dark, info);

    &:hover {
      border: 1px solid color($context-colors, info);
      color: color($context-colors, info)
    }
  }

  &.Success {
    background-color: dark-color($context-background-colors, success);
    color: color($context-colors-dark, success);

    &:hover {
      border: 1px solid color($context-colors, success);
      color: color($context-colors, success)
    }
  }

  &.Warning {
    background-color: dark-color($context-background-colors, warning);
    color: color($context-colors-dark, warning);

    &:hover {
      border: 1px solid color($context-colors, warning);
      color: color($context-colors, warning)
    }
  }

  &.Failure {
    background-color: dark-color($context-background-colors, failure);
    color: color($context-colors-dark, failure);

    &:hover {
      border: 1px solid color($context-colors, failure);
      color: color($context-colors, failure)
    }
  }

  &:hover {
    background-color: transparent;
  }

}

.Toolbar {
  min-width: 60px;
  max-width: 90px;
  min-height: 60px;
  color: color($icon-colors, second);
  border: none;

  &:hover { 
    color: color($icon-colors-hover, second);
  }
}

.Icon {
  background-color: rgba(0, 0, 0, 0);

  
  &:not(.WithText) {
    border: none;
  }
  
  &.WithText {
    padding: $spacer-sm;
    margin-right: $spacer-lg;
  }
}

.Icon.Spinner {
  transition: transform ease-in-out 0.6s;
  &:hover {
    transform: rotate(90deg);
    transform-origin: center;
  }
}

