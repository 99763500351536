@import '../../variables';

.Length {
  display: flex;
  align-items: center;
}

.LengthCalc {
  display: inline-block;
  font-weight: bold;
  font-size: 1.2rem;
  padding-left: $spacer-lg;
  display: flex;
  flex-flow: column;
}