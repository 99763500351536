@import '../../../variables';

.Select {
  background-color: color($background-colors, input-first);
  // border: 1px solid color-dark($background-colors, input-first);
  border: none;
  position: relative;
  color: color($colors, input-first);
  min-height: 40px;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;

  &::-ms-expand {
    display: none;
  }
}

// .SelectHidden {
//   display: none;
// }

// .changeBtn {
//   height: 100%;
//   display: inline-block;
//   position: absolute;
//   right: 0;
//   background-color: transparent;
//   border: none;
//   cursor: pointer;
//   font-size: 10px;
//   color: black;
//   width: 40px;
//   line-height: 100%;
//   text-align: center;
// }

.Label {
  margin-bottom: $spacer-sm;
}

// .changeBtn:focus {
//   outline: 0;
// }

// .changeBtn:hover {
//   color: rgb(0, 73, 144)
// }


.SelectContainer {

  position:relative; 
  display: inline;

  &::after {
    content:""; 
    width:0; 
    height:0; 
    position:absolute; 
    pointer-events: none;
  }

  &[disabled] {
    & select {
      background-color: transparent;
    }
  }

  &:not([disabled])::after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    top: 17.5px;
    right: $spacer-sm;
    border-top: 8px solid color($colors, first);
  }
}