.Page {
  width: 100%;
  height: 100%;

  cursor: move;
  cursor: grab;
}

.Page:active {
  cursor: grabbing;
}

.SvgPreview {
  font-family: 'Encode Sans Condensed', sans-serif !important;
}