@import '../../../variables';

.Input {
  display: inline-block;
  width: 100%;
  // border: 1px solid color-dark($background-colors, input-first);
  border: none;
}

.Label {
  margin-bottom: $spacer-sm;
}