@import '../../../variables';

.Input {
  flex-flow: row;
  align-items: center;
}

.Checkbox {
  width: $spacer-lg;
}

.Label {
  margin-left: $spacer-lg;
  margin-bottom: 0;
}