@import '../../../variables';

.Notification {
  border-radius: 2px;
  margin: $spacer-sm 0;
  min-height: 50px;
  display: flex;
  pointer-events: auto;
  position: relative;
}

.Hidden {
  display: none;
}

.Padding {
  padding: 16px;
}


.IconBar {
  &.Success {
    background-color: dark-color($context-background-colors, success);
    color: dark-color($context-colors, success);
  }
  &.Warning {
    background-color: dark-color($context-background-colors, warning);
    color: dark-color($context-colors, warning);
  }
  &.Failure {
    background-color: dark-color($context-background-colors, failure);
    color: dark-color($context-colors, failure);
  }
  &.Info {
    background-color: dark-color($context-background-colors, info);
    color: dark-color($context-colors, info);
  }
}

.ContentContainer {
  flex-basis: 100%;

  &.Success {
    background-color: color($context-background-colors, success);
    color: color($context-colors, success);
  }
  &.Warning {
    background-color: color($context-background-colors, warning);
    color: color($context-colors, warning);
  }
  &.Failure {
    background-color: color($context-background-colors, failure);
    color: color($context-colors, failure);
  }
  &.Info {
    background-color: color($context-background-colors, info);
    color: color($context-colors, info);
  }
}

.Title {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: $spacer-sm;
}

.Content {
  margin-bottom: $spacer-lg;
}