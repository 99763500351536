@import '../../variables';

.Field {
  margin-bottom: $spacer-lg;
  display: flex;
  flex-flow: column;
  width: 100%;
}

.FixedHeight {
  height: 40px;
  line-height: 40px;
  padding-left: $spacer-sm;
  box-sizing: border-box;
}

.Field > label {
  font-size: 14px;
  font-weight: bold;
}

.Field > input,
.Field > select {
  outline: none;
}

.DesktopOnly {
  display: none;
}

@media (max-width: 599px) { 
  
}

@media (min-width: 600px) { 
  
}

@media (min-width: 900px) { 
  
}

@media (min-width: 1200px) {
  .DesktopOnly {
    display: initial;
  }
}

@media (min-width: 1800px) { 
  
}

