@import '../../variables';

.Editor {
  height: 100%;
  width: 100%;
  background-color: color($background-colors, third);
  display: flex;
  flex-direction: column-reverse;
  overflow: hidden;
}

.Nav {
  // z-index: 100;
  flex: 1 1 70px;
}

.Display {
  // flex-basis: 100%;
  flex: 0 1 100%;
  display: flex;
}

.PageContainer {
  // flex-basis: 0%;
  // flex-grow: 1;
  height: 100%;
  // width: 100%;
  flex: 1 0 0%;
  position: relative;
  transition: width 0.2s;
}

// .SidebarContainer {
//   position: relative;
//   flex-basis: 0%;
//   left: -100%;
//   transition: all 0.2s;
// }

// .SidebarContainer.Open {
//   flex-basis: 100%;
//   left: 0%;
//   transition: all 0.2s;
// }

@media only screen and (max-width: 600px) { 
  .PageContainer.Open {
    flex-basis: 0;
    left: 100%;
    transition: all 0.2s;
  }
}

@media only screen and (min-width: 600px) { 
  
}

@media only screen and (min-width: 900px) {

  .Editor {
    flex-direction: row;
  }

  // .SidebarContainer.Open {
  //   flex-basis: 45%;
  // }
}

@media only screen and (min-width: 1200px) {
  // .SidebarContainer.Open {
  //   flex-basis: 40%;
  // }

  .NotificationsContainer {
    width: 400px;
  }
}

@media only screen and (min-width: 1800px) { 
  // .SidebarContainer.Open {
  //   flex-basis: 35%;
  // }
}