// COLOR
$background-colors: (
  first: #eee,
  first-alt: #eee,
  second: rgb(0, 73, 144),
  third: rgb(59, 59, 59),
  input-first: white
);

$colors: (
  first: rgb(9, 9, 9),
  first-alt: rgb(0, 73, 144),
  second: white,
  third: white,
  input-first: rgb(59, 59, 59),
);


$icon-colors: (
  first: black,
  second: #bbb,
  third: #bbb
);

$icon-colors-hover: (
  first: #bbb,
  second: white,
  third: white
);

$context-background-colors: (
  info: rgb(215, 233, 255),
  success: rgb(154, 218, 141),
  warning: rgb(252, 229, 129),
  failure: rgb(255, 182, 169),
);

$context-colors: (
  info: map-get($colors, first),
  success: map-get($colors, first),
  warning: map-get($colors, first),
  failure: map-get($colors, first),
);

$context-colors-dark: (
  info: map-get($colors, first),
  success: map-get($colors, first),
  warning: map-get($colors, first),
  failure: map-get($colors, first),
);

$tile-selection-colors: (
  hover: map-get($background-colors, third),
  selected: map-get($background-colors, second),
  disabled: map-get($context-background-colors, failure),
  info: map-get($context-background-colors, info),
  color-hover: map-get($colors, third),
  color-selected: map-get($colors, second),
  color-disabled: map-get($context-colors, failure),
  color-info: map-get($context-colors, info),
);


@function color($color-list, $key) {
  @return map-get($color-list, $key);
}

@function dark-color($color-list, $key, $darken-amount: 25%) {
  @return darken(color($color-list, $key), $darken-amount);
}

// SIZE & POSITION
$spacer-lg: 16px;
$spacer-sm: $spacer-lg / 2;

$font-size-sm: 0.5em;
$font-size-md: 1em;
$font-size-lg: 1.5em;

@mixin box-shadow($color) {
  box-shadow: 5px 5px 10px darken($color, 50%);
}

@mixin for-size($range) {
  $phone-upper-boundary: 600px;
  $tablet-portrait-upper-boundary: 900px;
  $tablet-landscape-upper-boundary: 1200px;
  $desktop-upper-boundary: 1800px;

  @if $range == phone-only {
    @media (max-width: #{$phone-upper-boundary - 1}) { @content; }
  } @else if $range == tablet-portrait-up {
    @media (min-width: $phone-upper-boundary) { @content; }
  } @else if $range == tablet-landscape-up {
    @media (min-width: $tablet-portrait-upper-boundary) { @content; }
  } @else if $range == desktop-up {
    @media (min-width: $tablet-landscape-upper-boundary) { @content; }
  } @else if $range == big-desktop-up {
    @media (min-width: $desktop-upper-boundary) { @content; }
  }
}
