@import '../../../variables';

.Sidebar {
  background-color: color($background-colors, first);
  width: 100%;
  height: 100%;
  position: absolute;
  // transform: translate(-100%, 0%);
  top: 0;
  left: 0;
  padding: $spacer-sm;
  box-sizing: border-box;
  color: black;
  display: flex;
  flex-flow: column;
  z-index: 2;
}

.Root {
  position: relative;
  flex-basis: 0%;
  left: -100%;
  transition: all 0.2s;
}

.Open {
  flex-basis: 100%;
  left: 0%;
  transition: all 0.2s;
}

.Root Select {
  width: 100%;
}

// .Open {
//   transform: translate(0%, 0%);
// }

.Heading > h3 {
  display: inline-block;
  margin-bottom: $spacer-lg;
}

.Close {
  position: relative;
  float: right;
  height: 100%;
}

.Content {
  overflow-y: auto;
  flex: 1 100%;
  // min-height: 100%;
}






.Display {
  flex-basis: 100%;
  display: flex;
}

.PageContainer {
  flex-basis: 0%;
  flex-grow: 1;
  position: relative;
  transition: all 0.2s;
}


/* @media (max-width: 599px) { 
  
} */

@media only screen and (min-width: 600px) { 
  
}

@media only screen and (min-width: 900px) {
  .Open {
    flex-basis: 45%;
  }
}

@media only screen and (min-width: 1200px) {
  .Open {
    flex-basis: 40%;
  }
}

@media only screen and (min-width: 1800px) { 
  .Open {
    flex-basis: 35%;
  }
}