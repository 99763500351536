@import '../../../variables';

.IconLabel {
  margin-top: 5px;
  font-size: 0.4em;
  line-height: $font-size-sm + 1;

  @include for-size(tablet-landscape-up) {
    font-size: 0.5em;
  }
}

